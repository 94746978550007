import type { InductionsType, InductionsState } from './types';

export const initialInductionsObject: InductionsType = {
  induction_template: { metadata: { id: '' }, root_attributes: { name: '' } },
  steps_count: 0,
  completed_steps_count: 0
};

export const initialInductionsState: InductionsState = {
  inductionsData: { induction_instances: [], total_size: 0 },
  fetchingInductionsLoading: false,
  fetchingInductionsErrorCode: undefined
};
