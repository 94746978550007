import type { TFunction } from 'i18next';
// eslint-disable-next-line no-restricted-imports
import type { Localization } from '@safetyculture/sc-web-ui';

export const localizationWithI18next = (
  t: TFunction<string[], undefined>,
  ns?: string
): Localization => {
  return {
    locale: () => t('locale', { ns }),
    avatarGroup: {
      userCountTitle: (userNames: string, userOtherCount: number) =>
        userOtherCount > 0
          ? t('avatar-group.user-count-title', { userNames, userOtherCount, ns })
          : t('avatar-group.user-count-title', { userNames, userOtherCount, ns })
    },
    filterGroup: {
      newFeature: () => t('filter-group.new-feature', { ns })
    },
    timeline: {
      showMore: () => t('timeline.show-more', { ns }),
      showLess: () => t('timeline.show-less', { ns }),
      loadMore: () => t('timeline.load-more', { ns })
    },
    button: {
      done: () => t('button.done', { ns }),
      remove: () => t('button.remove', { ns }),
      addFilter: () => t('button.add-filter', { ns }),
      viewMore: () => t('button-view-more', { ns }),
      clearAll: () => t('button.clear-all', { ns })
    },
    card: {
      actionsLayout: {
        labelCountTitle: (labels: string, labelsOtherCount: number) =>
          labelsOtherCount > 0
            ? labelsOtherCount > 0
              ? `${labels}, +${labelsOtherCount}`
              : labels
            : labels
      }
    },
    carousel: {
      title: () => t('carousel.title', { ns }),
      gallery: () => t('carousel.gallery', { ns }),
      slide: () => t('carousel.slide', { ns }),
      showNext: () => t('carousel.show-next', { ns }),
      showPrev: () => t('carousel.show-previous', { ns })
    },
    pagination: {
      nextPage: () => t('pagination.next-page', { ns }),
      previousPage: () => t('pagination.previous-page', { ns }),
      lastPage: () => t('pagination.last-page', { ns }),
      inputLabel: (max: number) => t('pagination.input-label', { max, ns })
    },
    uploader: {
      invalidFileType: () => t('uploader.invalid-file-type', { ns }),
      dragHere: () => t('uploader.drag-here', { ns }),
      tryAgain: () => t('uploader.try-again', { ns }),
      browse: () => t('uploader.browse', { ns }),
      emptyFile: () => t('uploader.empty-file', { ns }),
      fileTooLarge: () => t('uploader.file-too-large', { ns }),
      or: () => t('uploader.or', { ns }),
      uploading: () => t('uploader.uploading', { ns }),
      dropFileHere: () => t('uploader.drop-file-here', { ns }),
      tooManyFiles: (maxCount: number) => t('uploader.too-many-files', { count: maxCount, ns })
    },
    hierarchicalPicker: {
      loadMore: () => t('hierarchical-picker.load-more', { ns })
    },
    formLabel: {
      optionalLabelDefault: () => t('form-label.secondary-text', { ns }),
      requiredLabelDefault: () => t('form-label.secondary-text-required', { ns })
    },
    typeLabel: {
      inspection: () => t('type-label.inspection', { ns }),
      action: () => t('type-label.action', { ns }),
      issue: () => t('type-label.issue', { ns }),
      course: () => t('type-label.course', { ns }),
      headsUp: () => t('type-label.heads-up', { ns }),
      rapidRefresh: () => t('type-label.rapid-refresh', { ns }),
      playlist: () => t('type-label.playlist', { ns }),
      brainBoost: () => t('type-label.brain-boost', { ns }),
      courseCollection: () => t('type-label.course-collection', { ns })
    },
    priorityLabel: {
      none: () => t('priority-label.none', { ns }),
      low: () => t('priority-label.low', { ns }),
      medium: () => t('priority-label.medium', { ns }),
      high: () => t('priority-label.high', { ns })
    },
    searchInput: {
      search: () => t('search-input.search', { ns }),
      clear: () => t('search-input.clear-search', { ns })
    },
    statusLabel: {
      'in-progress': () => t('status-label.in-progress', { ns }),
      completed: () => t('status-label.completed', { ns }),
      open: () => t('status-label.open', { ns }),
      resolved: () => t('status-label.resolved', { ns }),
      'to-view': () => t('status-label.to-view', { ns }),
      acknowledged: () => t('status-label.acknowledged', { ns }),
      unacknowledged: () => t('status-label.unacknowledged', { ns })
    },
    layouts: {
      courseLayout: {
        lessons: (numberOfLessons: number) =>
          numberOfLessons === 1
            ? t('layouts.course-layout.lesson', { ns })
            : t('layouts.course-layout.lessons', { numberOfLessons, ns }),
        lessonsTooltip: () => t('layouts.course-layout.lessons-tooltip', { ns })
      },
      rapidRefreshLayout: {
        sessions: (currentSession: number, totalSession: number) =>
          t('layouts.rapid-refresh-layout.sessions', {
            currentSession,
            totalSession,
            ns
          }),
        questions: (numberOfQuestions: number) =>
          numberOfQuestions === 1
            ? t('layouts.rapid-refresh-layout.question', { ns })
            : t('layouts.rapid-refresh-layout.questions', {
                numberOfQuestions,
                ns
              })
      },
      brainBoostLayout: {
        questions: (numberOfQuestions: number) =>
          numberOfQuestions === 1
            ? t('layouts.rapid-refresh-layout.question', { ns })
            : t('layouts.rapid-refresh-layout.questions', {
                numberOfQuestions,
                ns
              }),
        duration: (duration: number) => t('layouts.brain-boost-layout.duration', { duration, ns })
      },
      playlistLayout: {
        courses: (numberOfCourses: number) =>
          numberOfCourses === 1
            ? t('layouts.playlist-layout.course', { ns })
            : t('layouts.playlist-layout.courses', { numberOfCourses, ns })
      },
      inductionsLayout: {
        steps: (numberOfSteps: number) =>
          numberOfSteps === 1 ? '1 Step' : `${numberOfSteps} Steps`
      }
    },
    passwordInput: {
      showPassword: () => t('password-input.show-password', { ns }),
      hidePassword: () => t('password-input.hide-password', { ns })
    },
    modal: {
      closeButton: () => t('modal.close-button', { ns })
    },
    inputTag: {
      defaultLabel: () => t('input-tag.default-label', { ns })
    },
    catalogLayout: {
      titleImageAlt: (title: string) => t('catalog-layout.title-image-alt', { title, ns })
    },
    select: {
      placeholder: () => t('select.placeholder', { ns }),
      inputTriggerButtonAriaLabel: () => t('select.input-trigger-button-aria-label', { ns }),
      dropdownContentAriaLabel: () => t('select.dropdown-content-aria-label', { ns }),
      noResultsFound: () => t('select.no-results-found', { ns }),
      addItemButtonText: (value: string) => t('select.add-item-button-text', { value, ns })
    },
    dialogCloseButton: {
      closeBtn: () => t('dialog-close-button.close-btn', { ns })
    },
    table: {
      sortButton: () => t('table.sort-button', { ns }),
      addEditableCellValue: () => t('table.add-cell-value', { ns })
    },
    notification: {
      regionLabel: () => t('notification.region-label', { ns })
    },
    datePicker: {
      parseFailed: () => t("date-picker.parse-failed", { ns }),
      minExceeded: (minDate: string) => t("date-picker.min-exceeded", { ns, minDate }),
      maxExceeded: (maxDate: string) => t("date-picker.max-exceeded", { ns, maxDate }),
      disabledDate: () => t("date-picker.disabled-date", { ns }),
      requiredDate: () => t("date-picker.required-date", { ns }),
    }
  };
};
