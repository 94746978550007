import { initialAuthorProfileState } from '@edapp/author-profile';
import { initialContentLibraryState } from '@edapp/content-library';

import { initialAccountSettingsState } from './account-settings/reducer';
import { initialActionDialogState } from './action-dialog/constants';
import { initialBannersState } from './banners/reducer';
import { initialBrainBoostState } from './brain-boost/reducer';
import { initialConfigState } from './config/constants';
import { initialCoursewareState } from './courseware/constants';
import { CoursewareVersionType } from './courseware/types';
import { initialCustomAchievementsState } from './custom-achievements/reducer';
import { initialFeatureFlagsState } from './feature-flags/constants';
import { initialForgotPasswordState } from './forgot-password/constants';
import { initialGameLoadingState } from './game/loading/reducer';
import { initialLotteryState } from './game/lottery/reducer';
import { initialPlaysState } from './game/plays/reducer';
import { initialPrizesState } from './game/prizes/reducer';
import { initialWinnersState } from './game/winners/reducer';
import { initialHomeState } from './home/constants';
import { initialInteractionsState } from './interactions/constants';
import { inviteCodeSignUpInitialState } from './invite-code-sign-up/reducer';
import { initialLeaderboardsState } from './leaderboards/reducer';
import { initialLearnerDemographicState } from './learner-demographic/constants';
import { initialLessonDialogState } from './lesson-dialog/constants';
import { initialLocalNotificationState } from './local-notification/reducer';
import { initialLockedDialogState } from './locked-dialog/constants';
import { initialLogoutState } from './logout/constants';
import { initialMagicLinkState } from './magic-link/reducer';
import { initialNavigationState } from './navigation/reducer';
import { initialOfflineState } from './offline/reducer';
import { initialPerformanceState } from './performance/reducer';
import { initialProfileState } from './profile/reducer';
import { initialRatingDialogState } from './rating-dialog/constants';
import { initialRegisterState } from './register/reducer';
import { initialResetPasswordState } from './reset-password/constants';
import { initialSearchState } from './search/reducer';
import { initialSessionState } from './session/reducer';
import { initialSlideProgressState } from './slide-progress/reducer';
import { initialSocialLearningState } from './social-learning/reducer';
import { initialSplitViewLayoutState } from './split-view-layout/constants';
import { initialStarState } from './star/constants';
import { initialThomasState } from './thomas/reducer';
import { initialToastState } from './toast/reducer';
import { initialUserState } from './user/reducer';
import { initialInductionsState } from './inductions/constants';

export const initialStoreState: StoreWithPersistState = {
  _persist: {
    rehydrated: false,
    version: -1
  },
  offline: initialOfflineState,
  profile: initialProfileState,
  customAchievements: initialCustomAchievementsState,
  config: initialConfigState,
  courseware: initialCoursewareState,
  game: {
    loading: initialGameLoadingState,
    lottery: initialLotteryState,
    plays: initialPlaysState,
    prizes: initialPrizesState,
    winners: initialWinnersState
  },
  navigation: initialNavigationState,
  performance: initialPerformanceState,
  slideProgress: initialSlideProgressState,
  star: initialStarState,
  user: initialUserState,
  thomas: initialThomasState,
  interactions: initialInteractionsState,
  social_learning: initialSocialLearningState,
  local_notification: initialLocalNotificationState,
  brainBoost: initialBrainBoostState,
  lockedDialog: initialLockedDialogState,
  ratingDialog: initialRatingDialogState,
  actionDialog: initialActionDialogState,
  toast: initialToastState,
  banners: initialBannersState,
  inviteCodeSignUp: inviteCodeSignUpInitialState,
  contentLibrary: initialContentLibraryState,
  authorProfile: initialAuthorProfileState,
  learnerDemographic: initialLearnerDemographicState,
  forgotPassword: initialForgotPasswordState,
  resetPassword: initialResetPasswordState,
  register: initialRegisterState,
  lessonDialog: initialLessonDialogState,
  magicLink: initialMagicLinkState,
  leaderboards: initialLeaderboardsState,
  search: initialSearchState,
  session: initialSessionState,
  featureFlags: initialFeatureFlagsState,
  splitViewLayout: initialSplitViewLayoutState,
  home: initialHomeState,
  accountSettings: initialAccountSettingsState,
  logout: initialLogoutState,
  inductions: initialInductionsState
};

export const Urls = {
  SOCIAL_LEARNING: 'api/sociallearning',
  INTERACTIONS: 'api/Interactions',
  PEER_AUTHORING: 'api/peerAuthoring',
  BANNERS: 'api/banners/sync',
  COLLECTIONS: 'api/course-collections/sync',
  COURSE: (courseId: string) => `api/courses/${courseId}/sync`,
  COURSE_LESSONS: (courseId: string) =>
    `api/course/${courseId}/lessons?versionType=${CoursewareVersionType.Published}`, // By default LearnersApp requests should be using the published version
  COURSE_PROGRESS: 'api/courseprogress/sync',
  COURSE_RATING: (courseId: string) => `api/courses/${courseId}/rating`,
  COURSE_CERTIFICATE: (courseId: string, userId: string) =>
    `api/user-course-certificates/${courseId}/${userId}/details`,
  LEADERBOARD: (leaderboardId: string) => `api/v2/leaderBoard/instance/${leaderboardId}`,
  LEADERBOARDS_V2: 'api/v2/leaderBoard/instance/getAll',
  LESSON: 'api/lessons/sync',
  LESSON_PROGRESS: 'api/lesson-progress',
  LESSON_ATTEMPT_PROGRESS: (lessonId: string) => `api/lesson-progress/${lessonId}/attempt`,
  LESSON_ATTEMPT_PROGRESS_LIST: `api/lesson-progress/attempts`,
  LESSONS_ACCESS: 'api/lessons/access',
  GAME: 'api/game',
  RESET_PASSWORD: 'api/users/reset-password',
  RESET_PASSWORD_V2: 'api/v2/email/forgot-password',
  SLIDE_PROGRESS: 'api/slide-progress',
  STARS: 'api/stars',
  USER_SYNC: 'api/users/sync',
  ME: 'api/users/me',
  USER_PERFORMANCE: 'api/user-progress/current',
  LESSON_NOTIFICATIONS: 'api/lesson-progress/notifications',
  BRAIN_BOOST_SESSION: 'api/brainboost/sessions',
  BRAIN_BOOST_SLIDES: 'api/brainboost/session-slides',
  PLAYLISTS: 'api/playlists/sync',
  PLAYLIST_ITEM: (playlistId: string) => `api/playlists/${playlistId}/sync`,
  PLAYLIST_PROGRESSES: `api/playlists/progress/sync`,
  DISCUSSION_PROGRESS: (discussionId: string) => `api/discussions/${discussionId}/progress`,
  DISCUSSION_ITEM: (discussionId: string) => `api/discussions/${discussionId}`,
  DISCUSSION_POSTS: (discussionId: string) => `api/discussions/${discussionId}/posts`,
  APP_DISCUSSION_POSTS: (discussionId: string) => `api/app/discussions/${discussionId}/posts`,
  DISCUSSIONS_POST_SUBSCRIPTIONS: (postId: string) =>
    `api/discussions/post-subscriptions/${postId}`,
  DISCUSSION_POST_ITEM: (discussionId: string, postId: string) =>
    `api/discussions/${discussionId}/posts/${postId}`,
  APP_DISCUSSION_POST_ITEM: (discussionId: string, postId: string) =>
    `api/app/discussions/${discussionId}/posts/${postId}`,
  DISCUSSION_POSTS_COMMENTS: (discussionId: string) =>
    `api/discussions/${discussionId}/posts/comments`,
  DISCUSSION_POST_COMMENTS: (discussionId: string, postId: string) =>
    `api/discussions/${discussionId}/posts/${postId}/comments`,
  ASSESSMENT_ITEM: (assessmentId: string) => `api/assessments/${assessmentId}`,
  ASSESSMENT_POSTS: (assessmentId: string) => `api/assessments/${assessmentId}/posts`,
  APP_ASSESSMENT_POSTS: (assessmentId: string) => `api/app/assessments/${assessmentId}/posts`,
  ASSESSMENT_POST_SUBSCRIPTIONS: (postId: string) => `api/assessments/post-subscriptions/${postId}`,
  ASSESSMENT_POST_ITEM: (assessmentId: string, postId: string) =>
    `api/assessments/${assessmentId}/posts/${postId}`,
  APP_ASSESSMENT_POST_ITEM: (assessmentId: string, postId: string) =>
    `api/app/assessments/${assessmentId}/posts/${postId}`,
  ASSESSMENT_POST_COMMENTS: (assessmentId: string, postId: string) =>
    `api/assessments/${assessmentId}/posts/${postId}/comments`,
  ASSESSMENT_PROGRESS: (assessmentId: string) => `api/assessments/${assessmentId}/progress`,
  ASSESSMENT_POST_FEEDBACK: (assessmentId: string, postId: string) =>
    `api/assessments/${assessmentId}/posts/${postId}/comments/grade`,
  USER_VERIFY: `api/users/verify`,
  UPDATE_AVATAR: `api/users/me/avatar`,
  CONFERENCE: (conferenceId: string) => `api/conferences/${conferenceId}`,
  UPCOMING_CONFERENCES: `api/conferences/upcoming`,
  DEMOGRAPHIC_OPT_OUT: (questionId: string) => `api/demographic-questions/${questionId}/opt-out`,
  DEMOGRAPHIC_QUESTIONS: `api/demographic-questions/unanswered`,
  DEMOGRAPHIC_ANSWER: `api/demographic-questions/answers`,
  MICRO_CREDENTIAL: (microCredentialId: string) => `api/app/micro-credentials/${microCredentialId}`,
  EARNED_MICRO_CREDENTIALS: `api/app/micro-credentials`,
  MAGIC_LINK: 'api/email/magic-link',
  RAPID_REFRESH_LIST: 'api/rapid-refresh/sessions',
  RAPID_REFRESH_ITEM: (courseId: string, session: number) =>
    `api/rapid-refresh/${courseId}/sessions/${session}`,
  RAPID_REFRESH_LEADERBOARD: (courseId: string) => `api/rapid-refresh/${courseId}/leaderboard`,
  SEARCH_CATALOG: `api/search/catalog`,
  CUSTOM_ACHIEVEMENTS: 'api/custom-achievements/sync',
  LINK_ACCOUNT: 'api/accounts/link-account',
  SWITCH_ACCOUNT: 'api/accounts/switch-account',
  OBSERVATION_ITEM: (observationId: string) => `api/observations/${observationId}/results`,
  OBSERVATION_RESULTS: 'api/observations/results',
  LEARNERS_AWARDED_CERTIFICATES: 'api/user-course-certificates',
  LEARNERS_AWARDED_CERTIFICATE_DOWNLOAD_URL: (certificateId: string) =>
    `api/user-course-certificates/${certificateId}/download-link`,
  HOME_SCREEN_UP_NEXT_CRITERIA: 'api/home-screen/for-you/up-next/criteria',
  HOME_SCREEN_FOR_YOU_WIDGETS: 'api/home-screen/for-you/widgets',
  GROUP_TRAINING_SESSION: (sessionId: string) =>
    `api/group-training/sessions/${sessionId}/attendees`,
  USERS: `api/users`,
  SSO_CHECK: `api/authentication/sso-check`,
  INVITE_REGISTER: `api/register/app-user`,
  INVITE_SETTINGS: `api/authentication/invite-settings`,
  INVITE_CHECK: `api/authentication/invite-check`,
  LOGIN_SSO: `api/authentication/login-sso`,
  LOGIN: 'api/login/learner',
  SINGLE_USE_TOKEN: 'api/manage/single-use-token',
  BRIEFCASE_DOCUMENT_DOWNLOAD_URL: (documentId: string) =>
    `api/briefcase-document/${documentId}/download-link`,
  ASSIGNED_INDUCTIONS_LIST: '/api/v3/inductions/ui/v1/instances'
};
